import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">academic paper</Text>
                <Title variant="secSm" className="my-4">
                    Legistar Redesign{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Researched, analyzed and designed a system that addresses how the City of Seattle might implement a system 
                  that makes the decision-making process of council meetings more transparent and easily accessible online.
                  Produced a System Requirement Specification deliverable that provides an analysis of the system and a recommended
                  solution with implementation steps.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Role</Text>
                <Title variant="cardBig" className="mt-3">
                  Author
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan 2019 - Mar 2019
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://docs.google.com/document/d/1FN17CzXxHgfZC1aNnYA16nyBhdu9EN0qX-hyZfJxREY/edit?usp=sharing" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Requirements Specification</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section bg="dark" className="mt-lg-5">
          <Container style={{marginTop: 160}}>
            <div className="text-center">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/grub-run">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Grub Run
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
